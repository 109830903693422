<template>
  <div>
    <van-nav-bar
        :title="getTitle"
        :fixed=true
        :placeholder =true
        :left-text="getLeftText"
        :right-text='getRightText'
        :left-arrow='!this.$route.meta.ishome'
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      Toast('按钮');
    },
  },
  computed:{
    getTitle(){
      return this.$route.meta.title;
    },
    getLeftText(){
      return this.$route.meta.ishome ?"":"返回"
    },
    getRightText(){
      return this.$route.meta.ishome ?"":""
    }

  }
}
</script>

<style>

</style>