import Vue from 'vue'
import router from '@/router'
import { NavBar } from 'vant';
Vue.use(NavBar);
import { Grid, GridItem,Image } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
import { Lazyload } from 'vant';
Vue.use(Lazyload);
import { Divider } from 'vant';
Vue.use(Divider);
import { Field } from 'vant';
Vue.use(Field);
import { Form } from 'vant';
Vue.use(Form);
import { Button } from 'vant';
Vue.use(Button);
import { Slider } from 'vant';
Vue.use(Slider);
import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);
import { Tab, Tabs } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
import { DatetimePicker } from 'vant';
Vue.use(DatetimePicker);
import { Popup } from 'vant';
Vue.use(Popup);
import { RadioGroup, Radio } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);


import App from './App.vue'
Vue.config.productionTip = false
new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')
