<template>
  <div class="router-body">
    <h3>{{ pageName }}</h3>
    <van-divider>{{ pageShorttitle }}</van-divider>
    <div class="formbox">
      <van-form>
        <van-field
          v-model.number="zonghe"
          name="用户名"
          type="number"
          label="贷款总额"
          placeholder="请输入贷款金额（元）"
          :rules="[{ required: true }]"
        />
        <van-field name="slider" label="贷款年限">
          <template #input>
            <van-slider v-model.number="nianxian" min="1" max="120">
              <template #button>
                <div class="custom-button">{{ nianxian }}年</div>
              </template>
            </van-slider>
          </template>
        </van-field>


<van-field name="radio" label="贷款方式">
  <template #input>
    <van-radio-group v-model="daikuanType" direction="horizontal">
      <van-radio name="1" value='1'>等额本息</van-radio>
      <!-- <van-radio name="2" value='2'>等额本金</van-radio> -->
    </van-radio-group>
  </template>
</van-field>










        <van-field
          v-model.number="lilv"
          name="用户名"
          type="number"
          label="贷款利率"
          placeholder="请输入贷款利率（%）"
          :rules="[{ required: true }]"
        />
    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="timeValue"
      label="首还日期"
      placeholder="点击选择时间"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="currentDate"
        :minDate="minDate"
        :maxDate="maxDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    

    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="timeValue2"
      label="还款日期"
      placeholder="点击选择时间"
      @click="showPicker2 = true"
    />
    <van-popup v-model="showPicker2" position="bottom">
      <van-datetime-picker
        type="date"
        v-model="currentDate"
        :minDate="minDate"
        :maxDate="maxDate"
        @confirm="onConfirm2"
        @cancel="showPicker2 = false"
      />
    </van-popup>

<van-field name="radio" label="还款方式">
  <template #input>
    <van-radio-group v-model="huankuanType" direction="horizontal">
      <van-radio name="1" value='1'>一次结清</van-radio>
      <van-radio name="2" value='2'>部分结清</van-radio>
    </van-radio-group>
  </template>
</van-field>


 <div v-show="huankuanType==='2'">
        <van-field
          v-model.number="huankuanzonghe"
          name="用户名"
          type="number"
          label="还款总额"
          placeholder="请输入还款金额（元）"
          :rules="[{ required: true }]"
        />
    <van-field name="radio" label="还款方式">
      <template #input>
        <van-radio-group v-model="chongdaiType" direction="horizontal">
          <van-radio name="1" value='1'>缩短年限</van-radio>
          <van-radio name="2" value='2'>减少月供</van-radio>
        </van-radio-group>
      </template>
    </van-field>



    </div>








        <van-button icon="delete" type="warning" size="small" @click="reset()"
          >重 置</van-button
        >
        <van-button icon="video" type="info" size="small" @click="calu()"
          >计 算</van-button
        >
      </van-form>
    </div>

    <van-divider>计算结果</van-divider>

    <div class="formbox" style="margin-bottom: 20px; padding-bottom: 20px">


          <div class="box">
              <van-cell-group>
                <van-cell title="贷款总额" :value="`${zonghe}元`" />
                <van-cell title="月还款额" :value="`${caluResult.yyhke}元`" />
                <van-cell title="已还款额" :value="`${caluResult.yhkze}元`" />
                <van-cell title="该月还款" :value="`${caluResult.gyycxhke}元`" />
                <van-cell title="节省利息" :value="`${caluResult.jslxzc}元`" />
                
                <van-cell title="已还利息" :value="`${caluResult.yhlxzh}元`" />
                <van-cell title="下月起还" :value="`${caluResult.xyq}元`" />
                <van-cell title="最后还款（原）" :value="`${caluResult.yzhhkq}`" />
                <van-cell title="最后还款（现）" :value="`${caluResult.xdzhhkq}`" />
              </van-cell-group>
          </div>

    </div>
  </div>
</template>
<script>
import Big from "big.js";
import ResultBox from "@/components/Tools/Result.vue";
import { Toast } from "vant";
import dayjs from "dayjs"
export default {
  components: {
    ResultBox,
  },
  data() {
    return {
      timeValue:'',
      showPicker: false,
      timeValue2:'',
      showPicker2: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      huankuanType:"1",
      daikuanType:"1",
      chongdaiType:"1",
      pageName: "提前还款计算器",
      pageShorttitle: "等额本息默认利息不变",
      huankuanzonghe:0,
      zonghe: "",
      nianxian: 30,
      lilv: 4.3,
      activeName: "debx",
      caluResult: {
        yyhke: 0,
        yhkze: 0,
        gyycxhke: 0,
        jslxzc: 0,
        yzhhkq: "-",
        yhlxzh: 0,
        xyq: 0,
        xdzhhkq: "-",
      },
    };
  },
  methods: {
   onConfirm(time) {
      this.timeValue = dayjs(time).format('YYYY-MM-DD');
      this.showPicker = false;
    },
   onConfirm2(time) {
      this.timeValue2 = dayjs(time).format('YYYY-MM-DD');
      this.showPicker2 = false;
    },
    reset() {
      this.zonghe = "";
      this.nianxian = 30;
      this.lilv = 30;
    },
    calu() {
      // if (!this.zonghe) {
      //   Toast("贷款总额未输入");
      //   return false;
      // }
      // if (!this.huankuanzonghe) {
      //   Toast("还款总额未输入");
      //   return false;
      // }
      // if (this.huankuanzonghe>this.zonghe) {
      //   Toast("还款总额不能大于贷款总额");
      //   return false;
      // }


      //贷款总额
      const dkje = this.zonghe;
      //还款月数
      const dkqx = this.nianxian*12;
      //月利息
      const dklv = this.lilv / 12 / 100;
      //首次还款时间
      const Ftime=this.timeValue;
      //提前还款时间
      const Etime=this.timeValue2;
      //提前还款金额
      const txttqhkje=this.huankuanzonghe;


      //一次性结清
      if(this.huankuanType==='1'){
                //原每月月供
                const debxyg = (dkje * dklv * Math.pow((1 + dklv), dkqx)) / (Math.pow((1 + dklv),dkqx) - 1);
                //原最后还款期
                const hkYearJ =parseInt(dayjs(Ftime).year()) + parseInt(dkqx)/12;
                const hkMonth =dayjs(Ftime).month() + 1 - 1;
                const hkDate = dayjs(Ftime).date()
                const yzhhkq = dayjs(hkYearJ + "-" + hkMonth + "-" + hkDate).format('YYYY-MM-DD');
                //已还款总额
                const yhkyf=dayjs(Etime).diff(Ftime, 'month');  //已经还款的月份，截止提前还款日
                const yhkze=debxyg*yhkyf 
                //已还利息额
                let yhlxzh = 0;
                let zlx = 0;
                for (var i = 1; i <= dkqx; i++) {
                    if (i <= yhkyf) {
                        yhlxzh += dkje * dklv * (Math.pow((1 + dklv), dkqx) - Math.pow((1 + dklv), (i - 1))) / (Math.pow((1 + dklv), dkqx) - 1);
                        //已还利息总额
                    }
                }
                zlx = dkqx * debxyg - dkje;  //总利息
                //该月一次还款额
                const mylx = dkje * dklv * (Math.pow((1 + dklv), dkqx) - Math.pow((1 + dklv), (yhkyf))) / (Math.pow((1 + dklv), dkqx) - 1); //次月利息
                const mylx2 = parseFloat(dkje - yhkze + yhlxzh + mylx).toFixed(2)

                //节省利息支出
                const jslx = zlx - yhlxzh - mylx;   
                //提前还款结束时间
                const tqhkjssj = dayjs(Etime).format('YYYY-MM-DD');

                this.caluResult={
                    yyhke: parseFloat(debxyg).toFixed(2),   //每月月供
                    yhkze: parseFloat(yhkze).toFixed(2),    //已还款总额
                    gyycxhke: parseFloat(mylx2).toFixed(2), //该月还款额
                    jslxzc: parseFloat(jslx).toFixed(2),    //节省利息支出
                    yzhhkq: yzhhkq,                         //原最后还款期限
                    yhlxzh: parseFloat(yhlxzh).toFixed(2),  //已还利息总计
                    xyq: 0,                                 //下月起还
                    xdzhhkq: tqhkjssj,                      //最后最后还款时间
                  }


      }else{
          //缩短还款年限
          if(this.daikuanType==='1'){

                    var dychkn = dayjs(this.timeValue).year();
                    var dychky = dayjs(this.timeValue).month();
                   //已还款月数
                    const md=dayjs(this.timeValue2).diff(this.timeValue, 'month');  //已经还款的月份，截止提前还款日
                    
                    //原最后还款期
                    const hkYearJ =parseInt(dayjs(Ftime).year()) + parseInt(dkqx)/12;
                    const hkMonth =dayjs(Ftime).month() + 1 - 1;
                    const hkDate = dayjs(Ftime).date()
                    const yzhhkq = dayjs(hkYearJ + "-" + hkMonth + "-" + hkDate).format('YYYY-MM-DD');
                    //月供
                    const yg= (dkje *dklv * Math.pow((1 + dklv), dkqx)) / (Math.pow((1 + dklv),dkqx) - 1);
                    //已还款总额
                    var yhkze = yg * md;
                    //已还利息
                    var yhlx = 0;
                    var yhbj = 0;
                    for (var i = 1; i <= md; i++) {
                        yhlx = yhlx + (dkje - yhbj) * dklv;
                        yhbj = yhbj + yg - (dkje - yhbj) * dklv;
                    }
                    //计算
                    //提前还款金额
                    var ert = parseFloat(txttqhkje);
                    var wer = 0;
                    var qwa = 0;
                    var j = 0;
                    for (j = dkje - yhbj - ert; j >= 0; j = j - qwa) {
                        qwa = yg / Math.pow((1 + dklv), wer);
                        wer++;
                    }
                  //  alert(wer)
                    //下月起还款额
                    var vbr = (dkje - yhbj - yg - ert) * (dklv * Math.pow((1 + dklv), wer)) / (Math.pow((1 + dklv), wer) - 1);
                    //该月一次还款额
                    var bnf = yg + ert;
                    //节省利息支出
                    var thx = yg * dkqx - yhkze - bnf - vbr * wer;
                    //新的最后
                    var bncc = parseInt(dychkn + parseFloat((wer + md + dychky - 2) / 12)) + "年" + ((wer + md + dychky - 2) % 12 + 1) + "月";

                    //
                    vbr=parseFloat(vbr).toFixed(2);   //下月起还款额
                    bnf=parseFloat(bnf).toFixed(2);   //该月一次还款额
                    thx=parseFloat(thx).toFixed(2)    //节省利息支出
                    bncc=bncc                         //最后还款时间
                    yhkze=yhkze                       //已还款总额
                    yhlx=yhlx                         //已还利息


                this.caluResult={
                    yyhke: parseFloat(yg).toFixed(2),        //每月月供
                    yhkze: parseFloat(yhkze).toFixed(2),    //已还款总额
                    gyycxhke: parseFloat(bnf).toFixed(2), //该月还款额
                    jslxzc: parseFloat(thx).toFixed(2),    //节省利息支出
                    yzhhkq: yzhhkq,                         //原最后还款期限
                    yhlxzh: parseFloat(yhlx).toFixed(2),  //已还利息总计
                    xyq: vbr,                                 //下月起还
                    xdzhhkq: bncc,                      //最后最后还款时间
                  }


          }  else //减少月还款额
          {
                    const md=dayjs(this.timeValue2).diff(this.timeValue, 'month');  //已经还款的月份，截止提前还款日
                    //月供
                    const yg= (dkje *dklv * Math.pow((1 + dklv), dkqx)) / (Math.pow((1 + dklv),dkqx) - 1);
                    //最后还款期限
                    var zhhkqx = parseFloat((dychkn + (parseFloat(dkqx + (dychky - 2))) / 12)).toFixed(0) + "年" + (((dkqx + (dychky - 2)) % 12) + 1) + "月";
                    //已还款总额
                    var yhkze = yg * md;
                    //已还利息
                    var yhlx = 0;
                    var yhbj = 0;
                    for (var i = 1; i <= md; i++) {
                        yhlx = yhlx + (dkje - yhbj) * dklv;
                        yhbj = yhbj + yg - (dkje - yhbj) * dklv;
                    }
                    dxyhlxe.innerHTML = parseFloat(yhlx).toFixed(2) + "元";
                    //计算
                    //提前还款金额
                    var ert = parseFloat(txttqhkje);
                    var wer = 0;
                    var qwa = 0;
                    var j = 0;
                    for (j = dkje - yhbj - ert; j >= 0; j = j - qwa) {
                        qwa = yg / Math.pow((1 + dklv), wer);
                        wer++;
                    }

                    //下月起还款额
                    var vbr = (dkje - yhbj - yg - ert) * (dklv * Math.pow((1 + dklv), (dkqx - md))) / (Math.pow((1 + dklv), (dkqx - md)) - 1);
                    //该月一次还款额
                    var bnf = yg + ert;
                    //节省利息支出
                    var thx = yg * dkqx - yhkze - bnf - vbr * (dkqx - md);
                    //新的最后还款期
                    var bncc = parseFloat((dychkn + parseFloat((wer + md + dychky - 2) / 12))).toFixed(0) + "年" + ((wer + md + dychky - 2) % 12 + 1) + "月";
                    vbr=parseFloat(vbr).toFixed(2);   //下月起还款额
                    bnf=parseFloat(bnf).toFixed(2);   //该月一次还款额
                    thx=parseFloat(thx).toFixed(2)    //节省利息支出
                    zhhkqx=zhhkqx                         //最后还款时间
                    yhkze=yhkze                       //已还款总额
                    yhlx=yhlx                         //已还利息

          }


      }



      return false

    },
  },
  computed:{
    getDate(){
      return dayjs().format('YYYY-MM-DD');
    },
  },
  mounted(){
    this.timeValue=this.timeValue2=dayjs().format('YYYY-MM-DD');
    
  }
};
</script>

<style scoped>
.formbox {
  width: 90%;
  margin: auto;
  background: #fff;
}
.formbox button {
  width: 40%;
  margin: 5%;
}
.custom-button {
  width: 50px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #1989fa;
  border-radius: 100px;
}
.van-slider {
  margin: auto;
}
</style>

