<template>
  <div class="router-body">
    <h3>{{ pageName }}</h3>
    <van-divider>{{ pageShorttitle }}</van-divider>
    <div class="formbox">
      <van-form>
        <van-field
          v-model.number="zonghe"
          name="用户名"
          type="number"
          label="贷款总额"
          placeholder="请输入贷款金额（元）"
          :rules="[{ required: true }]"
        />
        <van-field name="slider" label="贷款年限">
          <template #input>
            <van-slider v-model.number="nianxian" min="1" max="120">
              <template #button>
                <div class="custom-button">{{ nianxian }}月</div>
              </template>
            </van-slider>
          </template>
        </van-field>

        <van-field
          v-model.number="lilv"
          name="用户名"
          type="number"
          label="贷款利率"
          placeholder="请输入贷款利率（%）"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model.number="lilv"
          name="用户名"
          type="number"
          label="贷款利率"
          placeholder="请输入贷款利率（%）"
          :rules="[{ required: true }]"
        />

        <van-button icon="delete" type="warning" size="small" @click="reset()"
          >重 置</van-button
        >
        <van-button icon="video" type="info" size="small" @click="calu()"
          >计 算</van-button
        >
      </van-form>
    </div>

    <van-divider>计算结果</van-divider>

    <div class="formbox" style="margin-bottom: 20px; padding-bottom: 20px">
      <van-tabs v-model="activeName">
        <van-tab title="等额本息" name="debx"
          ><ResultBox :caluResult="caluResult_BX"></ResultBox
        ></van-tab>
        <van-tab title="等额本金" name="debj"
          ><ResultBox :caluResult="caluResult_BJ"></ResultBox
        ></van-tab>
        <van-tab title="按月付息" name="ayfx"
          ><ResultBox :caluResult="caluResult_AY"></ResultBox
        ></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Big from "big.js";
import ResultBox from "@/components/Tools/Result.vue";
import { Toast } from "vant";
export default {
  components: {
    ResultBox,
  },
  data() {
    return {
      pageName: "信用贷款",
      pageShorttitle: "2023年1月20日贷款市场报价利率",
      zonghe: "",
      nianxian: 12,
      lilv: 4.3,
      activeName: "debx",
      caluResult_BX: {
        ze: 0,
        ys: 0,
        hk: 0,
        lx: 0,
        hj: 0,
        dj: 0,
      },
      caluResult_BJ: {
        ze: 0,
        ys: 0,
        hk: 0,
        lx: 0,
        hj: 0,
        dj: 0,
      },
      caluResult_AY: {
        ze: 0,
        ys: 0,
        hk: 0,
        lx: 0,
        hj: 0,
        dj: 0,
      },
    };
  },
  methods: {
    reset() {
      this.zonghe = "";
      this.nianxian = 30;
      this.lilv = 30;
    },
    calu() {
      if (!this.zonghe) {
        Toast("贷款总额未输入");
        return false;
      }

      //贷款总额
      this.dkje = this.zonghe;
      //还款月数
      this.dkqx = this.nianxian;
      //月利息
      this.dklv = this.lilv / 12 / 100;
      /**************************等额本息***************************/
      //每月月供
      this.debxyg = new Big(
        (this.dkje * this.dklv * Math.pow(1 + this.dklv, this.dkqx)) /
          (Math.pow(1 + this.dklv, this.dkqx) - 1)
      ).toFixed(2);
      //总利息
      this.debxzlx = new Big(this.dkqx * this.debxyg - this.dkje).toFixed(2);
      //本息合计
      this.debxhj = new Big(this.dkje).plus(this.debxzlx).toFixed(2);
      this.caluResult_BX = {
        ze: this.zonghe,
        ys: this.dkqx,
        hk: this.debxyg,
        lx: this.debxzlx,
        hj: this.debxhj,
        dj: 0,
      };
      /**************************等额本金***************************/
      //每月月供
      this.debxyg = new Big(
        (this.dkje / this.dkqx) + (this.dkje * this.dklv)
      ).toFixed(2);
      //每月递减
      this.debjdj = new Big((this.dkje / this.dkqx) * this.dklv).toFixed(2);
      this.debxyg='首月：'+this.debxyg;
      this.debjdj=this.debjdj
      //总利息
      this.debxzlx = new Big(((this.dkje / this.dkqx + this.dkje * this.dklv) + this.dkje / this.dkqx * (1 + this.dklv)) / 2 * this.dkqx - this.dkje).toFixed(2);
      //本息合计
      this.debxhj = new Big(this.dkje).plus(this.debxzlx).toFixed(2);
      this.caluResult_BJ = {
        ze: this.zonghe,
        ys: this.dkqx,
        hk: this.debxyg,
        lx: this.debxzlx,
        hj: this.debxhj,
        dj: this.debjdj,
      };

      /**************************先息后本***************************/
      //每月月供
      this.debxyg = new Big(
        (this.dkje * this.dklv)
      ).toFixed(2);
      //总利息
      this.debxzlx = new Big(this.debxyg*this.dkqx).toFixed(2);
      //本息合计
      this.debxhj = new Big(new Big(this.debxzlx).plus(this.dkje)).toFixed(2);
      this.caluResult_AY = {
        ze: this.zonghe,
        ys: this.dkqx,
        hk: this.debxyg,
        lx: this.debxzlx,
        hj: this.debxhj,
        dj: 0,
      };

    },
  },
};
</script>

<style scoped>
.formbox {
  width: 90%;
  margin: auto;
  background: #fff;
}
.formbox button {
  width: 40%;
  margin: 5%;
}
.custom-button {
  width: 50px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #1989fa;
  border-radius: 100px;
}
.van-slider {
  margin: auto;
}
</style>

