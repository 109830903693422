<template>
  <div class="router-body">
    <h3>{{ pageName }}</h3>
    <van-divider>{{ pageShorttitle }}</van-divider>
    <div class="formbox">
      <van-form>

        <van-field name="slider" label="贷款年限">
          <template #input>
            <van-slider v-model.number="nianxian" min="1" max="50">
              <template #button>
                <div class="custom-button">{{ nianxian }}年</div>
              </template>
            </van-slider>
          </template>
        </van-field>





        <van-field
          v-model.number="gongjijin"
          name="用户名"
          type="number"
          label="公积金贷款"
          placeholder="请输入公积金贷款金额（元）"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model.number="lilv_gjj"
          name="用户名"
          type="number"
          label="公积金利率"
          placeholder="请输入公积金贷款利率（%）"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model.number="shangye"
          name="用户名"
          type="number"
          label="商贷总额"
          placeholder="请输入商业贷款金额（元）"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model.number="lilv_sd"
          name="用户名"
          type="number"
          label="商贷利率"
          placeholder="请输入商业贷款利率（%）"
          :rules="[{ required: true }]"
        />
        

        <van-button icon="delete" type="warning" size="small" @click="reset()"
          >重 置</van-button
        >
        <van-button icon="video" type="info" size="small" @click="calu()"
          >计 算</van-button
        >
      </van-form>
    </div>

    <van-divider>计算结果</van-divider>

    <div class="formbox" style="margin-bottom: 20px; padding-bottom: 20px">
      <van-tabs v-model="activeName">
        <van-tab title="等额本息" name="debx"
          ><ResultBox :caluResult="caluResult_BX"></ResultBox
        ></van-tab>
        <van-tab title="等额本金" name="debj"
          ><ResultBox :caluResult="caluResult_BJ"></ResultBox
        ></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Big from "big.js";
import ResultBox from "@/components/Tools/Result.vue";
import { Toast } from "vant";
export default {
  components: {
    ResultBox,
  },
  data() {
    return {
      pageName: "组合贷款计算器",
      pageShorttitle: "2023年1月20日贷款市场报价利率",
      shangye: "",
      gongjijin: "",
      nianxian: 30,
      lilv_sd: 4.9,
      lilv_gjj: 3.27,
      activeName: "debx",
      caluResult_BX: {
        ze: 0,
        ys: 0,
        hk: 0,
        lx: 0,
        hj: 0,
        dj: 0,
      },
      caluResult_BJ: {
        ze: 0,
        ys: 0,
        hk: 0,
        lx: 0,
        hj: 0,
        dj: 0,
      },
    };
  },
  methods: {
    reset() {
      this.zonghe = "";
      this.nianxian = 30;
      this.lilv = 30;
    },
    calu() {
      if (!this.gongjijin && !this.shangye){
        Toast("重要选项未填写");
        return false;
      }
		var txtlv=this.lilv_sd
		var dkje=this.shangye
		var txtgjjdkje=this.gongjijin
		var txtgjjlv=this.lilv_gjj

			//贷款年数
			//var dkqx = parseFloat(dkqxSel);
			var dkqx=this.nianxian*12
			//商业贷款利率
	        var dklv = txtlv/100;
			//公积金年利率
	        var gjjdklv = txtgjjlv/100;
	        //公积金贷款总额
	        var gjjdkje = txtgjjdkje;
			//贷款月数
	        dkqx = dkqx * 12; //贷款月数
			//商业贷款月利率
	        dklv = dklv / 12; 
	        //公积金月利率
	        gjjdklv = gjjdklv / 12;
	        /**************************等额本息***************************/
	        //贷款总额
	        var zdkje = dkje + gjjdkje;
	        //每月月供
	        var debxyg=parseFloat((dkje*dklv*Math.pow((1+dklv),dkqx))/(Math.pow((1+dklv),dkqx)-1));
	        var debxgjjyg=(gjjdkje*gjjdklv*Math.pow((1+gjjdklv),dkqx))/(Math.pow((1+gjjdklv),dkqx)-1);
	        var debxzyg = debxyg + debxgjjyg;
	        //总利息
	        var debxzlx = dkqx * debxyg - dkje;
	        var debxgjjzlx = dkqx * debxgjjyg - gjjdkje;
	        var debxzzflx = debxzlx + debxgjjzlx;
	        //本息合计
	        var debxhj = zdkje + debxzzflx;
      this.caluResult_BX={
        ze: parseFloat(zdkje).toFixed(2),
        ys: dkqx/12,
        hk: parseFloat(debxzyg).toFixed(2),
        lx: parseFloat(debxzzflx).toFixed(2),
        hj: parseFloat(debxhj).toFixed(2),
        dj: 0,
      }
			
			/**************************等额本金***************************/

	        //每月月供 //每月递减
	        var debjdkze = (dkje / dkqx) + (dkje * dklv);
	        var debjgjjdkze = (gjjdkje / dkqx) + (gjjdkje * gjjdklv);
	        var debjzyg = debjdkze + debjgjjdkze;
	        var debjdj = (dkje / dkqx) * dklv;
	        var debjgjjdj = (gjjdkje / dkqx) * gjjdklv;
	        var debjzdj = debjdj + debjgjjdj;
	        //总利息
	        var debjzlx = ((dkje / dkqx + dkje * dklv) + dkje / dkqx * (1 + dklv)) / 2 * dkqx - dkje;
	        var debjzgjjlx=((gjjdkje/dkqx+gjjdkje*gjjdklv)+gjjdkje/dkqx*(1+gjjdklv))/2*dkqx-gjjdkje;
	        var debjzlxs = debjzlx + debjzgjjlx;
	        //本息合计
	        var debjblh = debjzlxs + zdkje;

      this.caluResult_BJ={
        ze: parseFloat(zdkje).toFixed(2),
        ys: dkqx/12,
        hk: parseFloat(debjzyg).toFixed(2),
        lx: parseFloat(debjzlxs).toFixed(2),
        hj: parseFloat(debjblh).toFixed(2),
        dj: parseFloat(debjzdj).toFixed(2),
      }
    },
  },
};
</script>

<style scoped>
.formbox {
  width: 90%;
  margin: auto;
  background: #fff;
}
.formbox button {
  width: 40%;
  margin: 5%;
}
.custom-button {
  width: 50px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #1989fa;
  border-radius: 100px;
}
.van-slider {
  margin: auto;
}
</style>

