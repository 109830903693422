<template>
<div class="box">
    <van-cell-group>
      <van-cell title="贷款总额" :value="`${$props.caluResult.ze}元`" />
      <van-cell title="还款月数" :value="`${$props.caluResult.ys}月`" />
      <van-cell title="每月还款" :value="`${$props.caluResult.hk}元`" />
      <van-cell title="每月递减" :value="`${$props.caluResult.dj}元`" />
      <van-cell title="支付利息" :value="`${$props.caluResult.lx}元`" />
      <van-cell title="本息合计" :value="`${$props.caluResult.hj}元`" />
    </van-cell-group>
</div>

</template>
<script>
export default {
    props:['caluResult'],
    mounted(){
       console.log(this.$props)
    }
}
</script>

<style scope>
.box{width:90%;margin: auto;margin-top:10px;}
</style>