//配置路由
import Vue from 'vue';
import VueRouter from 'vue-router';


//引入路由组件
import Home from '@/pages/Home'
import Shangdai from '@/pages/Tools/Shangdai.vue'
import Gongjijin from '@/pages/Tools/Gongjijin.vue'
import Diyadaikuan from '@/pages/Tools/Diyadaikuan.vue'
import Xinyongdaikuan from '@/pages/Tools/Xinyongdaikuan.vue'
import Tiqianhuankuan from '@/pages/Tools/Tiqianhuankuan.vue'
import Zuhedaikuan from '@/pages/Tools/Zuhedaikuan.vue'
//使用插件
Vue.use(VueRouter);
//配置路由
const router=new VueRouter({
    mode: "history",
    //配置路由
    routes:[
       {
        path:'/home',
        component:Home,
        meta:{
            index:1,
            ishome:true,
            title:"贷款计算器",
        }
       },





       //===============================================================
       {
            path:'/tools/shangdai',
            component:Shangdai,
            meta:{
                index:2,
                ishome:false,
                title:"商贷计算器",
            },
        },
        {
            path:'/tools/gongjijin',
            component:Gongjijin,
            meta:{
                index:2,
                ishome:false,
                title:"公积金计算器",
            },
        },
        {
            path:'/tools/diyadaikuan',
            component:Diyadaikuan,
            meta:{
                index:2,
                ishome:false,
                title:"信用贷款",
            },
        },
        {
            path:'/tools/xinyongdaikuan',
            component:Xinyongdaikuan,
            meta:{
                index:2,
                ishome:false,
                title:"抵押贷款",
            },
        },

        {
            path:'/tools/tiqianhuankuan',
            component:Tiqianhuankuan,
            meta:{
                index:2,
                ishome:false,
                title:"提前还款",
            },
        },
        {
            path:'/tools/zuhedaikuan',
            component:Zuhedaikuan,
            meta:{
                index:2,
                ishome:false,
                title:"组合贷款",
            },
        },
       {
        path:'/',
        redirect:'/home',
       }
    ]
})

//2.使用router.beforeeach对路由进行遍历，设置title
router.beforeEach((to, from, next) => {
 //beforeeach是router的钩子函数，在进入路由前执行
 if (to.meta.title) {
  //判断是否有标题
  console.log(to.meta.title)
  document.title = to.meta.title
 } else {
  document.title = '默认title'
 }
 next()
})



export default router;