<template>
  <div class='router-body'>
<van-swipe class="my-swipe" :autoplay="300000" indicator-color="white">
  <van-swipe-item v-for="(image, index) in images" :key="index"><img :src="image" style="width:100%" /></van-swipe-item>
</van-swipe>


<div v-for="(Menus,index) in IndexMenu" :key='index'>
<van-divider>{{Menus.name}}</van-divider>
<van-grid square>
<van-grid-item :to="Menu.url" v-for="(Menu,Menuindex) in Menus.Menulist" :key="Menuindex" :icon="Menu.icon" :text="Menu.text" />
</van-grid>
</div>



</div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        'https://www.yiban.cn/upload/system/202301/230118095852167569.jpg',
        'https://www.yiban.cn/upload/system/202301/230116094920248348.png',
        'https://www.yiban.cn/upload/system/202302/230202170131273458.jpg',
      ],
      IndexMenu:[
        {name:'贷款计算器','Menulist':
                              [{
                                icon:'images/1.png',
                                text:'商业贷款',
                                url:'/tools/shangdai',
                              },
                              {
                                icon:'images/2.png',
                                text:'公积金贷款',
                                url:'/tools/gongjijin',
                              },
                              {
                                icon:'images/3.png',
                                text:'抵押贷款',
                                url:'/tools/diyadaikuan',
                              },
                              {
                                icon:'images/4.png',
                                text:'信用贷款',
                                url:'/tools/xinyongdaikuan',
                              },
                              {
                                icon:'images/5.png',
                                text:'组合贷款',
                                url:'/tools/zuhedaikuan',
                              },
                              {
                                icon:'images/4.png',
                                text:'提前还款',
                                url:'/tools/tiqianhuankuan',
                              },
                              ]
        },
       
      ]
    };
  },
  methods:{
    // goLink(url){
    //   this.$router.push(url)
    // }
  }
}
</script>

<style scope>
  .my-swipe .van-swipe-item {

  }
</style>